import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { AuthGuard } from './shared/auth.guard';

// import { HeaderComponent } from './components/layout/header/header.component';
// import { FooterComponent } from './components/layout/footer/footer.component';
// import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
// import { LoginComponent } from './components/auth/login/login.component';
import { MainHomeComponent } from './components/layout2/main-home/main-home.component';
// import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
// import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
// import { HomeComponent } from './components/home/home.component';
// import { Home2Component } from './components/home2/home2.component';
// import { Theme1Component } from './components/theme1/theme1.component';
// import { Theme2Component } from './components/theme2/theme2.component';
// import { Theme3Component } from './components/theme3/theme3.component';
// import { Header1Component } from './components/layout/header1/header1.component';
// import { Header2Component } from './components/layout/header2/header2.component';
// import { Header3Component } from './components/layout/header3/header3.component';
// import { AboutUsComponent } from './components/about-us/about-us.component';
// import { CategoryComponent } from './components/category/category.component';
// import { ContactUsComponent } from './components/contact-us/contact-us.component';
// import { ProductComponent } from './components/product/product.component';
// import { ProductDetailsComponent } from './components/product-details/product-details.component';
// import { CartComponent } from './components/cart/cart.component';
// import { CheckoutComponent } from './components/checkout/checkout.component';
// import { TermsConditionComponent } from './components/terms-condition/terms-condition.component';
// import { ConfirmDialogComponent } from './components/checkout/confirm-dialog.component';
// import { ThankYouComponent } from './components/thank-you/thank-you.component';
// import { OrderTrackingComponent } from './components/order-tracking/order-tracking.component';

const routes: Routes = [
  { path: 'page-not-found', component: PageNotFoundComponent, data: { title: 'Page not found', roles: [1, 2, 3] }, canActivate: [AuthGuard] },
  // { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  // { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: 'Forgot password' } },
  // { path: 'change-password', component: ChangePasswordComponent, data: { title: 'Change password' }, canActivate: [AuthGuard] },
  // { path: ':slug', component: HomeComponent, data: { title: 'Home', roles: [1, 2, 3] }, canActivate: [AuthGuard] },
  // { path: ':slug/about-us', component: AboutComponent, data: { title: 'About Us', roles: [1, 2, 3] }, canActivate: [AuthGuard] },

  { path: '', component: MainHomeComponent, data: { title: 'Start Online Business without Investment | Earn Extra Income', roles: [1, 2, 3] }, canActivate: [AuthGuard] },
  // { path: ':slug', component: HomeComponent, data: { title: 'Home', roles: [1, 2, 3] }, canActivate: [AuthGuard] },
  // { path: ':slug/about-us', component: AboutUsComponent, data: { title: 'About Us', roles: [1, 2, 3] }, canActivate: [AuthGuard] },
  // { path: ':slug/category', component: CategoryComponent, data: { title: 'Category', roles: [1, 2, 3] }, canActivate: [AuthGuard] },
  // { path: ':slug/contact-us', component: ContactUsComponent, data: { title: 'Contact Us', roles: [1, 2, 3] }, canActivate: [AuthGuard] },
  // { path: ':slug/product/:id', component: ProductComponent, data: { title: 'Product', roles: [1, 2, 3] }, canActivate: [AuthGuard] },
  // { path: ':slug/product-details/:id', component: ProductDetailsComponent, data: { title: 'Product Details', roles: [1, 2, 3] }, canActivate: [AuthGuard] },
  // { path: ':slug/cart', component: CartComponent, data: { title: 'Cart', roles: [1, 2, 3] }, canActivate: [AuthGuard] },
  // { path: ':slug/checkout', component: CheckoutComponent, data: { title: 'Checkout', roles: [1, 2, 3] }, canActivate: [AuthGuard] },
  // { path: ':slug/terms-condition', component: TermsConditionComponent, data: { title: 'Terms and Condition', roles: [1, 2, 3] }, canActivate: [AuthGuard] },
  // { path: ':slug/thank-you', component: ThankYouComponent, data: { title: 'Thank You', roles: [1, 2, 3] }, canActivate: [AuthGuard] },
  // { path: ':slug/order-tracking', component: OrderTrackingComponent, data: { title: 'Home', roles: [1, 2, 3] }, canActivate: [AuthGuard] },

  { path: '**', redirectTo: '/page-not-found' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    SharedModule
  ],
  exports: [
    RouterModule,
    // HeaderComponent,
    // FooterComponent,
    // SidebarComponent,
    PageNotFoundComponent,
    // LoginComponent,
    MainHomeComponent,
    // ForgotPasswordComponent,
    // ChangePasswordComponent,
    // HomeComponent,
    // Home2Component,
    // Theme1Component,
    // Theme2Component,
    // Theme3Component,
    // Header1Component,
    // Header2Component,
    // Header3Component,
    // AboutUsComponent,
    // CategoryComponent,
    // ContactUsComponent,
    // ProductComponent,
    // ProductDetailsComponent,
    // CartComponent,
    // CheckoutComponent,
    // TermsConditionComponent,
    // ConfirmDialogComponent,
    // ThankYouComponent,
    // OrderTrackingComponent
  ],
  declarations: [
    // HeaderComponent,
    // FooterComponent,
    // SidebarComponent,
    PageNotFoundComponent,
    // LoginComponent,
    MainHomeComponent,
    // ForgotPasswordComponent,
    // ChangePasswordComponent,
    // HomeComponent,
    // Home2Component,
    // Theme1Component,
    // Theme2Component,
    // Theme3Component,
    // Header1Component,
    // Header2Component,
    // Header3Component,
    // AboutUsComponent,
    // CategoryComponent,
    // ContactUsComponent,
    // ProductComponent,
    // ProductDetailsComponent,
    // CartComponent,
    // CheckoutComponent,
    // TermsConditionComponent,
    // ConfirmDialogComponent,
    // ThankYouComponent,
    // OrderTrackingComponent
  ],
  entryComponents: [
    // Home2Component,
    // Theme1Component,
    // Theme2Component,
    // Theme3Component,
    // Header1Component,
    // Header2Component,
    // Header3Component,
    // ConfirmDialogComponent
  ],
  providers: [
  ],
})
export class AppRoutingModule { }
