 $(document).ready(function () 
 {   

    $(document).ready(function(e){
  var $as = $('#slider ul.pager li a');
  var slider = $('.bxslider').bxSlider({
    auto: true,
    pager: false,
    controls: false,
    autoControls: false,
    speed: 1500,
    mode: 'fade',
    autoHover: true,
    onSlideAfter: function($el, pidx, idx) {
      $as.eq(pidx).removeClass('active');
      $as.eq(idx).addClass('active');
      }
  });
  $("#slider ul.pager li a").on('click', function (e) {
    slider.goToSlide($(this).data('slide-index'));
    //slider.stopAuto();
    $("#slider ul.pager li a").removeClass('active');
    $(this).addClass('active');
  });
  
  });




    /***** responsive menu ********/    
    $("#nav a.menu-icon").click(function(e) 
    {
          e.preventDefault();
          $("#nav ul").slideToggle(300);
    });
    if($(window).width() <= 768)
    {
         $("#nav ul li a").click(function() 
         {          
           $("#nav ul").fadeOut(300);
         });
    }
  

    $(window).scroll(function() 
    {    
        var scroll = $(window).scrollTop();

        if (scroll >= 300) 
        {
            $(".header-block").addClass("isStuck");
            $(".header-block").addClass("fadeInDown");
            $(".header-block").addClass("wow");
            $(".header-block").addClass("animated");
        } 
        else
        {
            $(".header-block").removeClass("isStuck");
            $(".header-block").removeClass("fadeInDown");
            $(".header-block").removeClass("wow");
            $(".header-block").removeClass("animated");
        }
        
    });

    $('#nav ul li a').click(function(e) {
        //e.preventDefault();

        $('#nav ul li a').removeClass('active');              
        var url = $(this).attr('dataid');
        $('#nav ul li a[dataid="'+url+'"]').addClass('active'); 


        
        var str = url.substring(url.indexOf('#')+1);
        if(str != '' && $('#'+str).length > 0) {
            //console.log(url.substring(url.indexOf('#')+1));
          $('html, body').animate({
              scrollTop: $('#' + url.substring(url.indexOf('#')+1)).offset().top - 80
          }, 500);
        }
      });



      $(document).on("click", "a.selling_partner_btn", function(e){
        var url = $(this).attr('dataid');        
        var str = url.substring(url.indexOf('#')+1);
        if(str != '' && $('#'+str).length > 0) {
            //console.log(url.substring(url.indexOf('#')+1));
          $('html, body').animate({
              scrollTop: $('#' + url.substring(url.indexOf('#')+1)).offset().top - 80
          }, 500);
        }
      })
      // $('a.selling_partner_btn').click(function(e) 
      // {
      //   //e.preventDefault();          
      //   var url = $(this).attr('dataid');        
      //   var str = url.substring(url.indexOf('#')+1);
      //   if(str != '' && $('#'+str).length > 0) {
      //       //console.log(url.substring(url.indexOf('#')+1));
      //     $('html, body').animate({
      //         scrollTop: $('#' + url.substring(url.indexOf('#')+1)).offset().top - 80
      //     }, 500);
      //   }
      // });

    


    
});


 