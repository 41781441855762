import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
// import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// import { TooltipModule } from 'ngx-bootstrap/tooltip';
// import { DataTablesModule } from 'angular-datatables';
// import { NgxDropzoneModule } from 'ngx-dropzone';
// import { NgSelectModule } from '@ng-select/ng-select';
// import { TreeviewModule } from 'ngx-treeview';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import { NgxGalleryModule } from 'ngx-gallery';

import { ValidationComponent } from '../components/validation/validation.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { ErrorLogService } from '../services/error-log.service';
import { ConfirmationDialogService } from '../services/confirmation-dialog.service';
import { CartService } from '../services/cart.service';
import { SafePipe } from './sanitizing';
import { WindowRefService } from '../services/window-ref.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
      enableHtml: true
    }),
    NgxSpinnerModule,
    SlickCarouselModule,
    ModalModule.forRoot(),
    // BsDatepickerModule.forRoot(),
    // TooltipModule.forRoot(),
    // DataTablesModule,
    // NgxDropzoneModule,
    // NgSelectModule,
    // TreeviewModule.forRoot(),
    // CKEditorModule,
    // NgxGalleryModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ValidationComponent,
    ConfirmationDialogComponent,
    SafePipe,
    NgxSpinnerModule,
    SlickCarouselModule
    // DataTablesModule,
    // BsDatepickerModule,
    // TooltipModule,
    // NgxDropzoneModule,
    // NgSelectModule,
    // TreeviewModule,
    // CKEditorModule,
    // NgxGalleryModule
  ],
  declarations: [
    ValidationComponent,
    ConfirmationDialogComponent,
    SafePipe
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ],
  providers: [
    DatePipe,
    ErrorLogService,
    ConfirmationDialogService,
    CartService,
    WindowRefService
  ]
})
export class SharedModule { }
