<!DOCTYPE html>
<html>

<head>
    <title>Start Online Business without Investment | Earn Extra Income</title>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
</head>

<body>

    <header id="header">
        <div class="header-top-block">
            <div class="container">
                <div class="header-top-content">
                    <div class="row ">
                        <div class="col-md-3"></div>
                        <div class="col-md-9">
                            <ul class="contact_info text-right">
                                <li>
                                    <div class="contact_box">
                                        <p><span class="icons"><i class="fa fa-phone"></i></span> <a href="tel:+919987019995">(+91) 9987019995</a></p>
                                    </div>
                                </li>
                                <li>
                                    <div class="contact_box">
                                        <p><span class="icons"><i class="fa fa-envelope"></i></span><a href="mailto:info@MyStore-E.in">info@mystore-e.in</a></p>
                                    </div>
                                </li>
                                <li class="ml-3"><a href="https://MyStore-E.in/masterpanel/" class="btn bnt-white">Login</a></li>
                                <li><a href="javascript:void(0);" dataid="#becomeasellingpartner" class="btn bnt-white selling_partner_btn">I Want to Be A Selling Partner</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-block">
            <div class="container">
                <div class="header-box">
                    <div class="logo-box">
                        <img src="assets/main/images/logo.png" alt="MyStore-E - Start online Business">
                    </div>
                    <div class="main-nav">
                        <nav id="nav">
                            <a href="#" class="menu-icon"><span></span><span></span><span></span></a>
                            <ul>
                                <li><a class="active" href="javascript:void(0);" dataid="#header">Home</a></li>
                                <li><a href="javascript:void(0);" dataid="#about">About Us</a></li>
                                <li><a href="javascript:void(0);" dataid="#packages">Packages</a></li>
                                <li><a href="javascript:void(0);" dataid="#inquirenow">Contact Us</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="slider">
        <div class="slider-block">
                <ul class="bxslider">
                    <li>
                        <img src="assets/main/images/banner1_mystore-e.jpg" alt="Mystore-e Business without investment">
                    </li>
                    <li>
                        <img src="assets/main/images/banner2_own_your_business.jpg" alt="extra income ideas for everyone">
                    </li>
                    <li>
                        <img src="assets/main/images/banner3_be_a_selling_partner.jpg" alt="start business without investment">
                    </li>
                    <li>
                        <img src="assets/main/images/banner4_cashback_referral.jpg" alt="Start Online Business">
                    </li>
                </ul> 
                <div class="slider-box">
                    <div class="container">   
                        <ul class="pager">
                            <li> <a class="btn btn-yellow active" data-slide-index="0" href="javascript:void(0);">MYSTORE-E</a>
                            </li>
                            <li> <a class="btn btn-yellow" data-slide-index="1" href="javascript:void(0);">OWN YOUR BUSINESS</a>
                            </li>
                            <li> <a class="btn btn-yellow" data-slide-index="2" href="javascript:void(0);">BE A SELLING PARTNER</a>
                            </li>
                            <li> <a class="btn btn-yellow" data-slide-index="3" href="javascript:void(0);">CASHBACK & REFERRAL</a>
                            </li>
                        </ul>
                     </div>         
                </div>
        </div>
      </section>

    

    <section id="step">
        <div class="step-block">
            <div class="container">
                <div class="step-box">
                    <h1>Your Business, Your Profits, Your Story… </h1>
                    <p>Make it possible with MyStore-E<sup>TM</sup></p>
                    <p>MyStore-E<sup>TM</sup> empowers you to own your business where you control your profits, your sales, your marketing, your customer. It empowers you to unleash your business spirit online and offline. All the rest is taken care by MyStore-E<sup>TM</sup>. </p>

                    <div class="row">
                        <div class="col-md-4 mt-3">
                            <div class="steps-box">
                                <div class="steps-icons">
                                    <img src="assets/main/images/home.png">
                                </div>
                                <h4>MyStore-E<sup>TM</sup> Commitment</h4>
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#commitment_model" class="read-more">read more</a>
                            </div>
                        </div>
                        <div class="col-md-4 mt-3">
                            <div class="steps-box">
                                <div class="steps-icons">
                                    <img src="assets/main/images/selling.png">
                                </div>
                                <h4>Selling Partner Commitment</h4>
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#selling_model" class="read-more">read more</a> 
                            </div>    
                        </div>
                        <div class="col-md-4 mt-3">
                            <div class="steps-box">
                                <div class="steps-icons">
                                    <img src="assets/main/images/empowerment.png">
                                </div>
                                <h4>MyStore-E<sup>TM</sup> Empowerment</h4>
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#empowerment_model" class="read-more">read more</a> 
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="about">
        <div class="about-block">
            <div class="row m-0 p-0">
                <div class="col-md-6 m-0 p-0">
                    <div class="about-img">
                        <div class="about-images">
                            <img src="assets/main/images/about.jpg" alt="online jewellery business from home">
                        </div>
                    </div>
                </div>
                <div class="col-md-6 m-0 p-0">
                    <div class="about-content">
                        <div class="about-cont">
                            <div class="section-title">
                                <h1>ABOUT US</h1>
                            </div>
                           <p>MyStore-E<sup>TM</sup> was born with a single powerful belief that, given an opportunity, every person yearns to control his/her own life-story. Our collective social instinct is that, being a business owner means, taking control of your destiny. Owning your own business is a powerful idea on several levels, but the most powerful idea is that owning a business means scripting your own life-story. </p>

                            <p>While there are several drivers for owning your own business; like independence, financial freedom, better lifestyle, family security etc., the underlying driver is Respect… Self-respect and social respect that is derived as a business owner.</p>

                            <p>MyStore-E<sup>TM</sup> mission is to provide a powerful business platform for people who never thought possible to own their own business. A platform for those enterprising people have dreams and passion but lack the tools and resources to write their story. MyStore-E<sup>TM</sup> endeavours to unleash their passion and express their story through their own business. </p>

                            <p>MyStore-E<sup>TM</sup> provides a platform where anyone can own a business to sell high quality lifestyle products in India, under their own brand, with freedom to determine their own profit and own markets. This business can be conducted online and offline. This opportunity is open to every Indian, irrespective of gender, religion, location, financial status, societal status etc.</p>

                            <p>Anyone with drive and ability to sell can take use this opportunity and make it their life-story. </p>
                            <p>Join us and write your story with MyStore-E<sup>TM</sup></p>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    </section>

    <section id="support">
        <div class="support-block">
            <div class="container">
                <div class="support-box">
                    <h1>How MyStore-E<sup>TM</sup> Empowers:</h1>
                    <ul>
                        <li>Invests high quality lifestyle products</li>
                        <li>Provides free website to the Selling Partner with their brand name.</li>
                        <li>For offline sales – provides high quality display (with your brand) with actual products</li>                        
                        
                        <!-- <li>Website is with your name and brand</li> -->
                        <li>Provides infrastructure for payments – Debit/Credit card machine, UPI, Net banking etc</li>
                        <li>Stocks thousands of products for you at any given time</li>
                        <li>Provides freedom to decide your profits and your price </li>
                        <li>Free listing of professional pictures of the products on your website</li>
                        <li>Freedom to sell across all online and offline channels.</li>
                        <li>Manages stock churn and dead stock</li>
                        <li>Provides product delivery</li>
                        <li>Market Analytics (On your admin console)</li>
                        <li>Provides auto-invoice generation from your website</li>
                        <li>Provides infrastructure to develop brand name & logo</li>                        
                        <li>Training and handholding facilities </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <section id="videos">
        <div class="video-block">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="video-box">
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/d6taAfsOAWw?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <br/>
                            <h5 class="text-center">Hindi Version</h5>
                        </div> 
                    </div> 
                    <div class="col-md-6">
                        <div class="video-box">
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/pbeFco0h4DY?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <br/>
                            <h5 class="text-center">English Version</h5>
                        </div> 
                    </div> 
                </div>
            </div>    
        </div>
    </section>

    <section id="becomeasellingpartner">
        <div class="content-block">
            <div class="container">
                <div class="section-title text-center">
                    <h1>Become A Selling Partner</h1>
                </div>
                <div class="content-box text-center">
                    <p>We hope you become a part of this incredible journey and enjoy this opportunity as much as we
                        enjoy offering them to you. </p>
                    <div class="frm_contacts">
                        <form id="frm_contacts" [formGroup]="distributorForm" (submit)="onDistributorSubmit()">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="FirstName"
                                            placeholder="First Name" />
                                        <validation-messages [control]="distributorForm.controls.FirstName">
                                        </validation-messages>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="LastName"
                                            placeholder="Last Name" />
                                        <validation-messages [control]="distributorForm.controls.LastName">
                                        </validation-messages>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="Email"
                                            placeholder="Email" />
                                        <validation-messages [control]="distributorForm.controls.Email">
                                        </validation-messages>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="Mobile"
                                            placeholder="Mobile No." />
                                        <validation-messages [control]="distributorForm.controls.Mobile">
                                        </validation-messages>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="CompanyName"
                                            placeholder="Company Name/Your Name" />
                                        <validation-messages [control]="distributorForm.controls.CompanyName">
                                        </validation-messages>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="PanCard"
                                            placeholder="Pan Card (optional)" />
                                        <validation-messages [control]="distributorForm.controls.PanCard">
                                        </validation-messages>
                                    </div>
                                </div>                                
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <select class="custom-select form-control" formControlName="State" (change)="getCity($event.target.value)">
                                            <option value="">Select State</option>
                                            <option *ngFor="let data of state_data" [value]="data.StateID">{{data.StateName}}</option>
                                        </select>
                                        <validation-messages [control]="distributorForm.controls.State">
                                        </validation-messages>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <select class="custom-select form-control" formControlName="City">
                                            <option value="">Select City</option>
                                            <option *ngFor="let data of city_data" [value]="data.CityID">{{data.CityName}}</option>
                                        </select>
                                        <validation-messages [control]="distributorForm.controls.City">
                                        </validation-messages>
                                    </div>
                                </div>
                            </div>
                             <div class="row">
                                <div class="col-md-6">                                   
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="ZipCode"
                                            placeholder="Zip Code" />
                                        <validation-messages [control]="distributorForm.controls.ZipCode">
                                        </validation-messages>
                                    </div>
                                     <div class="form-group">
                                        <input type="text" class="form-control" formControlName="ReferralCode"
                                            placeholder="Referral Code (optional)" />
                                        <!-- <validation-messages [control]="distributorForm.controls.Referral Code">
                                        </validation-messages> -->
                                    </div>
                                    <div class="form-group">
                                        <select class="custom-select form-control" formControlName="Package">
                                            <option value="">Select Package</option>
                                            <option *ngFor="let data of package_data" [value]="data.PackageID">{{data.PackageName}}</option>
                                        </select>
                                        <validation-messages [control]="distributorForm.controls.Package">
                                        </validation-messages>
                                    </div>
                                </div> 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <textarea class="form-control" formControlName="Address"
                                            placeholder="Address" rows="4"></textarea>
                                        <validation-messages [control]="distributorForm.controls.Address">
                                        </validation-messages>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="custom_chk">I, hereby agree with the
                                            <a href="javascript:void(0)" data-toggle="modal" data-target="#terms_model">
                                                Terms & Conditions</a>
                                            <input type="checkbox" formControlName="TermsConditions">
                                            <span class="checkmark"></span>
                                        </label>
                                        <validation-messages [control]="distributorForm.controls.TermsConditions">
                                        </validation-messages>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-4 mt-1">
                                    <input type="submit" name="sendbtn" id="sendbtn" value="SEND MESSAGE"
                                        class="btn btn-yellow w-100">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="packages">
        <div class="packages-block">
            <div class="container">
                <div class="section-title text-center">
                    <h1>OUR PACKAGES</h1>
                </div>
                <div class="packages-box">
                    <div class="row m-0">
                        <!-- <option *ngFor="let data of state_data" [value]="data.StateID">{{data.StateName}}</option> -->
                            <div class="col-md-3 p-0" *ngFor="let data of package_full_data">
                                <div class="packages-content-box">
                                    <h4>{{data.PackageName}}</h4>
                                    <div class="price-box">
                                        <h5>&#x20b9;{{data.PackagePrice}}<sup style="font-size: 14px !important;">*</sup></h5>
                                    </div>
                                    <h6 class="text-center text-blue mb-3" style="color: #1d1753;">{{data.PackageType}}</h6>
                                    <div [innerHTML]="data.PackageDescription"></div>
                                    <div class="button-box">
                                        <a href="javascript:void(0);" dataid="#becomeasellingpartner" (click)="selectPackage(data.PackageID)" class="btn btn-blue selling_partner_btn">Join Us</a>
                                    </div>                              
                                </div>
                            </div>

                        <!-- <div class="col-md-3 p-0">
                            <div class="packages-content-box">
                                <h4>Combo Package: </h4>
                                <div class="price-box">
                                    <h5>&#x20b9;35000</h5>
                                </div>
                                <p>Online website with your brand and logo</p>
                                <p class="border-top padding-box">Jewellery display with your brand and logo</p>
                                <p class="border-top padding-box">18 pieces of high quality fashion jewellery (Pre-set designs and categories)</p>
                                <p class="border-top padding-box">Market Analytics tool (embedded in your website)</p>
                                <p class="border-top padding-box">Website Admin and console</p>
                                <p class="border-top padding-box">Standard website training</p>
                                <p class="border-top padding-box">Resource for debit/credit card machine</p>
                                <p class="border-top padding-box">Branding and logo will be provided by you. If you wish to develop your logo, MyStore-E<sup>TM</sup> will provide the resources.</p>  
                                <div class="button-box">
                                    <a href="javascript:void(0);" dataid="#content" id="content_forms" (click)="selectPackage(1)" class="btn btn-blue">Join Us</a>
                                </div>                              
                            </div>
                        </div>

                        <div class="col-md-3 p-0">
                            <div class="packages-content-box">
                                <h4>Digital Package: </h4>
                                <div class="price-box">
                                    <h5>&#x20b9;18000</h5>
                                </div>
                                <p>Online website with your brand and logo</p>
                                <p class="border-top padding-box">Market Analytics tool (embedded in your website)</p>
                                <p class="border-top padding-box">Website Admin and console</p>
                                <p class="border-top padding-box">Standard website training</p>
                                <p class="border-top padding-box">Resource for debit/credit card machine</p>
                                <p class="border-top padding-box">Branding and logo will be provided by you. If you wish to develop your logo, MyStore-E<sup>TM</sup> will provide the resources. </p>
                                <div class="button-box">
                                    <a href="javascript:void(0);" dataid="#content" id="content_forms" (click)="selectPackage(2)" class="btn btn-blue">Join Us</a>
                                </div>  
                            </div>
                        </div>

                        <div class="col-md-3 p-0">
                            <div class="packages-content-box">
                                <h4>Digital + Offline Package: </h4>
                                <div class="price-box">
                                    <h5>&#x20b9;45000</h5>
                                </div>
                                <p>This package offers option to have only online package initially. You can opt for offline display with your brand name & logo + 20 pieces jewellery at a later time. </p>
                                <p class="border-top padding-box">Online website with your brand and logo</p>
                                <p class="border-top padding-box">Market Analytics tool (embedded in your website)</p>
                                <p class="border-top padding-box">Website Admin and console</p>
                                <p class="border-top padding-box">Standard website training</p>
                                <p class="border-top padding-box">Resource for debit/credit card machine <br />
                                (1) Jewellery display with your brand and logo <br />
                                (2) 20 pieces of high quality fashion jewellery (Pre-set designs and categories)</p>
                                <p class="border-top padding-box">Branding and logo will be provided by you. If you wish to develop your logo, MyStore-E<sup>TM</sup> will provide the resources. </p>
                                <div class="button-box">
                                    <a href="javascript:void(0);" dataid="#content" id="content_forms" (click)="selectPackage(3)"  class="btn btn-blue">Join Us</a>
                                </div>  
                            </div>
                        </div>

                        <div class="col-md-3 p-0">
                            <div class="packages-content-box">
                                <h4>Customised Package: </h4>
                                <div class="price-box">
                                    <h5>On Request</h5>
                                </div>
                                <p>This package contains online website + Jewellery Display + 20 pieces of customised
                                    jewellery</p>
                                <p class="border-top padding-box">Website* and Admin console. Website will have your
                                    name and brand logo. Branding and Logo will be provided by you. If you wish to
                                    develop own logo, MyStore-E<sup>TM</sup> will provide the resources. </p>
                                <p class="border-top padding-box">Standard jewellery display with your branding# </p>
                                <p class="border-top padding-box">20 pieces of jewellery as per standard set </p>
                                <p class="border-top padding-box">Website training </p>
                                <div class="button-box">
                                    <a href="javascript:void(0);" dataid="#content" id="content_forms" (click)="selectPackage(4)"  class="btn btn-blue">Join Us</a>
                                </div>  
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="inquirenow">
        <div class="contact-block">
            <div class="container">
                <div class="contact-box">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="contact-img">
                                <img src="assets/main/images/contact.png">
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="contct-form">
                                <h1>Inquire Now</h1>
                                <form id="frm-contact" [formGroup]="contactForm" (submit)="onSubmit()">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" formControlName="Name" class="form-control"
                                                    placeholder="Name">
                                                <validation-messages [control]="contactForm.controls.Name">
                                                </validation-messages>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" formControlName="Phone" class="form-control"
                                                    placeholder="Phone">
                                                <validation-messages [control]="contactForm.controls.Phone">
                                                </validation-messages>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="email" formControlName="Email" class="form-control"
                                                    placeholder="Email">
                                                <validation-messages [control]="contactForm.controls.Email">
                                                </validation-messages>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" formControlName="Message" class="form-control"
                                                    placeholder="Message">
                                                <validation-messages [control]="contactForm.controls.Message">
                                                </validation-messages>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">
                                            <input type="submit" name="btnsend" id="btnsend" value="SEND MESSAGE"
                                                class="btn btn-yellow w-100">
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-content-block">
            <div class="container">
                <div class="contact-content-box">
                    <div class="row">
                         <div class="col-md-4">
                            <div class="contact-content">
                                <ul>
                                    <li class="icons">
                                        <div class="icons-box">
                                            <i class="fa fa-map-marker"></i>
                                        </div>
                                    </li>
                                    <li class="content">
                                        <h6>Address: </h6>
                                        <p>Innovarium, Unit 204, <br/> Lotus Court B-Wing, <br /> Dr. A.B. Road, Worli, Mumbai - 400 018
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="contact-content">
                                <ul>
                                    <li class="icons">
                                        <div class="icons-box">
                                            <i class="fa fa-phone"></i>
                                        </div>
                                    </li>
                                    <li class="content">
                                        <h6>Connect Us:</h6>
                                        <p><a href="+919987019995">(+91) 9987019995</a></p>
                                    </li>
                                </ul>
                            </div>
                        </div>                       
                        <div class="col-md-4">
                            <div class="contact-content">
                                <ul>
                                    <li class="icons">
                                        <div class="icons-box">
                                            <i class="fa fa-envelope"></i>
                                        </div>
                                    </li>
                                    <li class="content">
                                        <h6>Email Us:</h6>
                                        <p><a href="mailto:info@MyStore-E.in">info@mystore-e.in</a></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="message_box">
        <p>Free Selling Partner Membership Offer for Limited Time Period</p>
    </div>

    <footer id="footer">
        <div class="footer-block">
            <div class="container">
                <div class="footer-box">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="social-icons">
                                <ul>
                                    <li><a href="https://www.facebook.com/Mystoreeonline-113431526962729/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="https://instagram.com/mystoreeonline" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                            <div class="footer-copyright">
                                <p>&copy; 2020 Innovarium. All Rights Reserved. <br /> Powered By: <a
                                        target="_blank" href="https://hetalarts.com/"> Hetal Arts</a></p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            
                            <div class="footer-nav">
                                <nav id="nav">
                                    <ul>
                                        <li><a class="active" href="javascript:void(0);" dataid="#header">Home</a></li>
                                        <li><a href="javascript:void(0);" dataid="#about">About Us</a></li>
                                        <li><a href="javascript:void(0);" dataid="#packages">Packages</a></li>
                                        <li><a href="javascript:void(0);" dataid="#inquirenow">Contact Us</a></li>
                                        <li><a href="javascript:void(0);" data-toggle="modal" data-target="#faq_model">FAQ’S</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <div class="modal custom_model" id="terms_model">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close close_btn" data-dismiss="modal">&times;</button>
                    <div class="model-contact_block">
                        <div class="section-title">
                            <h1>terms & conditions</h1>
                        </div>
                        <h4>SHIPPNG POLICY</h4>
                        <p>We promise to provide you with best-in-class shopping experience at every step of the way. We assure you that all due precautions are taken so that your package reaches you intact. An estimated delivery time period will be communicated to you at the time of order confirmation. On placing your order, you will receive an email/confirmation call containing a summary of the order and also the estimated delivery time to your location.</p>

                        <p>Sometimes, delivery may take longer due to bad weather, flight delays, political disruptions and other unforeseen circumstances. In such cases, we will proactively reach out to you. Please check your emails and SMS regularly for such updates.
                        </p>

                        <p>The delivery time period varies from product to product due to stock availability at your order dispatch center. We are not responsible for unsatisfactory or delayed performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.</p>

                        <h4>RETURNS AND REFUND POLICY</h4>
                        <p><b>1. Return To Origin: </b></p>
                        <ul>
                            <li>When a product is undelivered by courier due to technical reasons or external circumstances, we will refund the full amount after receiving the product at our facility.</li>                            
                            <li>When a product is lost by courier in transit. We will refund the full amount at the same time.</li>
                        </ul>

                        <p><b>2. Customer Returns: </b></p>
                        <ul>
                            <li>Customer returns will be accepted onlywhen the product delivered is incorrect or damaged in transit. In such case, the Selling Partner must report within 24 hours of receipt the product delivered. No returns will be entertained beyond 24 hours.
                            </li>

                            <li>The Selling Partner can contact support team via email to raise ticket within 24 hours from delivery. Our team will request for certain proofs of damages, wrong item delivered.</li>
                        </ul>

                        <p><b>Please read the following terms and conditions very carefully as your use of service is subject to your acceptance of and compliance with the following terms and conditions ("Terms").</b></p>

                        <p>By subscribing to or using any of our services you agree that you have read, understood and are bound by the Terms, regardless of how you subscribe to or use the services. If you do not want to be bound by the Terms, you must not subscribe to or use our services. These Terms and various other policies are binding as per the provisions of the Information Technology (Intermediaries guidelines) Rules, 2011 formulated under the Information Technology Act of 2000. In these Terms, references to "you", "User" shall mean the end user accessing the Website, its contents and using the Services offered through the Website,
                        "Service Providers" mean independent third party service providers, and "we", "us" and "our" shall mean Innovarium and its affiliates.</p>

                        <h4>1. Introduction:</h4>
                        <ul>
                            <li>www.mystore-e.in is an e-commerce portal licensed by Innovarium, a company incorporated under the laws of India. </li>
                            <li>Use of the Website is offered to you conditioned on acceptance without modification of all the terms, conditions and notices contained in these Terms, as may be posted on the Website from time to time. Innovarium, at its sole discretion, reserves the right not to accept a User from registering on the Website without assigning any reason thereof.</li>
                        </ul>    

                        <h4>2. User Account, Password, and Security:</h4>
                        <p>You will receive a password and account information upon completing the Website's registration process. You are responsible for maintaining the confidentiality of the password and account, and are fully responsible for all activities that occur under your
                        password or account. You agree to </p>
                        <ul>
                            <li>immediately notify Innovarium of any unauthorized use of your password or account or any other breach of security, and 
                            </li>
                            <li>ensure that you Logout from your account at the end of each session.www.mystore-e.in and/or
                            Innovarium cannot and will not be liable for any loss or damage arising from your failure to
                            comply with this Section 2.</li>
                        </ul>
                            
                        <h4>3. Services Offered:</h4>
                        <p>www.mystore-e.in provides a number of Internet-based services through the Web Site (all such services, collectively, the "Service"). One such service enables users to purchase original merchandise from www.mystore-e.in and its associates. The Products can be purchased through the Website through various methods of payments offered. The sale/purchase of Products shall be additionally governed by specific policies of sale, like cancellation policy, return policy, etc. (which are found on the following link …… ) and all of which are incorporated here by reference. In addition, these terms and policies may be further supplemented by Product 
                        specific conditions, which may be displayed on the webpage of that Product.</p>

                        <h4>4. Privacy Policy:</h4>
                        <p>The User hereby consents, expresses and agrees that he has read and fully understands the Privacy Policy of www.mystore-e.in and Innovariu. The user further consents that the terms and contents of such Privacy Policy are acceptable to him.</p>

                        <h4>5. Limited User:</h4>
                        <p>The User agrees and undertakes not to reverse engineer, modify, copy, distribute, transmit, display, download, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Website. Limited reproduction and copying of the content of the Website is permitted provided that www.mystore-e.in name is stated as the source and prior written permission of www.mystore-e.in is sought. For the removal of doubt, it is clarified that unlimited or wholesale reproduction, copying of the content for commercial or non-commercial purposes and unwarranted modification of data and information within the content of the Website is not permitted.</p>

                        <h4>6. User Conduct and Rules:</h4>
                        <p>You agree and undertake to use the Website and the Service only to post and upload messages and material that are proper. By way of example, and not as a limitation, you agree and undertake that when using a Service, you will not:</p>
                        <ul>
                            <li> Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others; </li>

                            <li> Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information; </li>

                            <li> Upload files that contain software or other material protected by intellectual property laws unless you own or control the rights thereto or have received all necessary consents; you own or control the rights thereto or have received all necessary consents; </li>

                            <li>Upload or distribute files that contain malware, viruses, corrupted files, or any other similar software or programs that may damage the operation of the Website or another's computer; </li>

                            <li>Conduct or forward surveys, contests, pyramid schemes or chain letters;</li>

                            <li>Download any file posted by another user of a Service that you know, or reasonably should know, cannot be legally distributed in such manner; </li>
                            <li>Falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded; </li>

                            <li>Violate any code of conduct or other guidelines, which may be applicable for or to any particular Service; </li>

                            <li>Violate any applicable laws or regulations for the time being in force in or outside India; and </li>

                            <li>Violate, abuse, unethically manipulate or exploit any of the terms and conditions of this Agreement or any other terms and conditions for the use of the Website contained elsewhere. </li>
                        </ul>    



                        <h4>7. User Warranty and Representation:</h4>
                        <p>The user guarantees, warrants, and certifies that you are the owner of the content which you submit or otherwise authorized to use the content and that the content does not infringe upon the property rights, intellectual property rights or other rights of others. You further warrant that to your knowledge, no action, suit, proceeding, or investigation has been instituted or threatened relating to any content, including trademark, trade name service mark, and copyright formerly or currently used by you in connection with the Services rendered by www.mystore-e.in.</p>

                        <h4>8. Exactness Not Guaranteed:</h4>
                        <p>www.mystore-e.in hereby disclaims any guarantees of exactness as to the finish and appearance of the final Product as ordered by the user. The quality of any products, Services, information, or other material purchased or obtained by you through the Website may not meet your expectations. Alterations to certain aspects of your order such as the merchandise brand, size, color etc. may be required due to limitations caused by availability of product difference in size charts of respective brands etc. In this instance you agree that www.mystore-e.in will send an approval request via email address, which you submitted when placing your order. If you do not agree with the requested change you retain the right to reject the requested production change by replying to it within 2 days of it being sent to you. www.mystore-e.in may re-request that you accept a production alteration one additional time 
                        if an alternative method to send your merchandise is available. If you reject this 2nd request your order will be cancelled and you will be fully refunded via your initial method of payment.</p>


                        <h4>9. Intellectual Property Rights:</h4>
                        <ul>
                            <li> Unless otherwise indicated or anything contained to the contrary or any proprietary material owned by a third party and so expressly mentioned, Innovarium owns all Intellectual Property Rights to and into the Website, including, without limitation, any and all rights, title and interest in and to copyright, related rights, patents, utility models, trademarks, trade names, service marks, designs, know-how, trade secrets and inventions (whether patentable or not), goodwill, source code, meta tags, databases, text, content, graphics, icons, and hyperlinks. You acknowledge and agree that you shall not use, reproduce or distribute any content from the Website belonging to Innovarium without obtaining authorization from Innovarium.</li>

                            <li>Notwithstanding the foregoing, it is expressly clarified that you will retain ownership and shall solely be responsible for any content that you provide or upload when using any Service, including any text, data, information, images, photographs, music, sound, video or any other material which you may upload, transmit or store when making use of our various Service. However, with regard to the product customization Service (as against other Services like blogs and forums) you expressly agree that by uploading and posting content on to the Website for public viewing and reproduction/use of your content by third party users,
                            you accept the User whereby you grant a non-exclusive license for the use of the same. </li>
                        </ul>    

                        <h4>10. END CUSTOMER DATABASE</h4>
                        <p>The Selling Partner Database and End Customer Database shall be proprietary to Innovarium and MyStore-E<sup>TM</sup>. Innovarium and MyStore-E<sup>TM</sup> shall alone retain all rights including all Intellectual Property Rights in the Selling Partner Database and End Customer Database and unless specifically agreed to in writing by Innovarium & MyStore-E<sup>TM</sup>, no rights in or to the Selling Partner Database and End Customer Database are deemed to have been granted to the Selling Partner and/or to any of their representatives. To the extent the Selling Partner derives any rights in the End Customer Database by virtue of it undertaking the Selling Partner Business the Selling Partner shall hold such rights in trust for Innovarium & MyStore-E<sup>TM</sup> and the Selling Partner shall do and undertake all such acts to exclusively assign such rights in the End Customer Database to Innovarium & MyStore-E<sup>TM</sup>.</p>

                        <p><b>The Merchant further agrees that: </b></p>
                        <ul>                            
                            <li>all the End CustomerDatabase shall be treated as Confidential Information of Innovarium & My Store-E for the purposes of this Agreement; </li>

                            <li> Innovarium & MyStore-E<sup>TM</sup> being the owner and proprietor of the End Customer Database shall be entitled to use, store, share and exploit the same in any manner as may be deem fit by Innovarium & MyStore-E<sup>TM</sup> and in accordance with Innovarium and MyStore-E<sup>TM</sup> privacy policy as provided on the Platform from time to time; and </li>

                            <li>Selling Partner shall not use the End Customer Database other than selling the Products by availing Innovarium and MyStore-E<sup>TM</sup> Business or required for law enforcement purposes and shall in no way sell, transfer or otherwise exploit the End Customer Database without the express written consent of Innovarium & MyStore-E<sup>TM</sup>. </li>
                        </ul>

                        <h4>11. Disclaimer of Warranties/Limitation of Liability:</h4>
                        <p>Innovarium and www.mystore-e.in has endeavored to ensure that all the information on the Website is correct, but Innovarium and/or www.mystore-e.in neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data, information, product or Service. In no event shall Innovarium and/or MyStore-E<sup>TM</sup> be liable for any direct, indirect, punitive, incidental, special, consequential damages or any other damages resulting from:</p>
                        <ul>
                            <li>The use or the inability to use the Services or Products; </li>
                            <li>Unauthorized access to or alteration of the user's transmissions or data; </li>
                            <li>Any other matter relating to the services; including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of the Website or Service. Neither shall Innovarium and/or MyStore-E<sup>TM</sup> be responsible for the delay or inability to use the Website or related services, the provision of or failure to provide Services, or for any information, software, products, services and related graphics obtained through the Website, or otherwise arising out of the use of the website, whether based on contract, tort, negligence, strict liability or otherwise.
                            Further, Innovarium and/or MyStore-E<sup>TM</sup> shall not be held responsible for non-availability of the Website during periodic maintenance operations or any unplanned suspension of access to the website that may occur due to technical reasons or for any reason beyond www.Innovariumayurved.net's control. The user understands and agrees that any material and/or data downloaded or otherwise obtained through the Website is done entirely at their own discretion and risk and they will be solely responsible for any damage to their computer systems or loss of data that results from the download of such material and/or data. </li>
                        </ul>    


                        <h4>12. Indemnification:</h4>
                        <p>You agree to indemnify, defend and hold harmless Innovarium and MyStore-E<sup>TM</sup> from and against any and all losses, liabilities, claims, damages, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Innovarium and/or www.mystore-e.in that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by you pursuant to these Terms.</p>

                        <h4>13. Pricing:</h4>
                        <p>Prices for products are described on our Website and are incorporated into these Terms by reference. All prices are in Indian rupees. Prices, products and Services may change at Innovarium’s and/or www.www.mystore-e.in discretion.</p>

                        <h4>14. Shipping:</h4>
                        <p>Title and risk of loss for all products ordered by you shall pass on to you, upon MyStore-E<sup>TM</sup> shipment to the shipping carrier. Also refer our Shipping Policy Link on our Website under Our Policies.</p>

                        <h4>15. Termination:</h4>
                        <ul>
                           <li>Innovarium and/or www.mystore-e.in may suspend or terminate your use of the Website or any Service if it believes, in its sole and absolute discretion that you have breached, violated, abused, or unethically manipulated or exploited any term of these Terms or anyway otherwise acted unethically. </li>

                           <li>Notwithstanding Section 14.a above, these Terms will survive indefinitely unless and until Innovarium and/or www.mystore-e.in chooses to terminate them. </li>

                           <li>If you or Innovarium and/or www.mystore-e.in terminates your use of the Website or any Service, Innovarium and/or www.mystore-e.in may delete any content or other materials relating to your use of the Service and Innovarium and/or www.mystore-e.in will have no liability to you or any third party for doing so. </li>

                           <li>You shall be liable to pay for any Service or product that you have already ordered till the time of Termination by either party whatsoever. Further, you shall be entitled to your royalty payments as per the User License Agreement that has or is legally deemed accrued to you. </li>
                        </ul>   

                        <h4>16. Governing Law:</h4>
                        <p>These terms shall be governed by and constructed in accordance with the laws of India without reference to conflict of laws principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of the courts at Haridwar, Uttarakhand</p>

                        <h4>17. Severability:</h4>
                        <p>If any provision of the Terms is determined to be invalid or unenforceable in whole or in part, such invalidity or unenforceability shall attach only to such provision or part of such provision and the remaining part of such provision and all other provisions of these Terms shall continue to be in full force and effect.</p>

                        <h4>18. Report Abuse:</h4>
                        <p>As per these Terms, users are solely responsible for every material or content uploaded on to the Website. Users can be held legally liable for their contents and may be held legally accountable if their contents or material include, for example, defamatory comments or material protected by copyright, trademark, etc. If you come across any abuse or violation of these Terms, please report at ecommerce@Innovariumayurved.org, with Subject heading “Report Abuse”.</p>

                        <h4>19. My Privilege terms of use:</h4>
                        <p><b>1. ELIGIBILITY, MEMBERSHIP, ACCRUAL</b></p>
                        <ul>
                            <li>These terms and conditions are operational only in India and open to participation of all the registered members, resident of India of www.Innovariumayurved.net, over and above the age of 18 years. </li>

                            <li>The program may be extended to other geographies and other audiences at Innovarium’s and/or www.mystore-e.in discretion.</li>
                            
                            <li>Membership will be identified by a single registered email id confirmed by the registered member at time of first login on the www.mystore-e.in webpage.</li>

                            <li>Two email id's cannot be combined under a single membership.</li>

                            <li>Membership will be activated for existing and new members/customers on registering with www.mystore-e.in </li>
                            
                            <li>Membership is valid for life until such time as www.mystore-e.in decides to terminate this Website i.e. www.mystore-e.in, with or without notice to its members. </li>

                            <li>Members will be charged for annual maintenance of the website. These charges may be revised from time to time as per sole discretion of Innovarium and/or www.mystore-e.in </li>
                        </ul>    
                        <p><b>2. GENERAL TERMS AND CONDITIONS</b></p>
                        <ul>
                            <li>Each member is responsible for remaining knowledgeable about the www.mystore-e.in program’s Terms and Conditions..</li>

                            <li>Innovarium and/or www.mystore-e.in will not be liable or responsible for correspondence lost or delayed in the mail/e-mail.
                            </li>

                            <li>Innovarium and/or www.mystore-e.in reserves the right to refuse, amend, vary or cancel membership of any Member without assigning any reason and without prior notification. </li>

                            <li>Any change in the name, address, or other information relating to the Member must be notified to www.mystore-e.in via the Helpdesk/email by the Member, as soon as possible at _______ 6 Days a Week. </li>

                            <li>Innovarium and/or www.mystore-e.in reserves the right to add, modify, delete or otherwise change the Terms and Conditions without any approval, prior notice or reference to the Member. </li>

                            <li>In the event of dispute in connection with www.mystore-e.in program and the interpretation of Terms and Conditions, Innovarium’s and/or www.mystore-e.in decision shall be final and binding.  </li>

                            <li>This Policy and these terms shall be read in conjunction with the standard legal policies of Innovarium and/or www.mystore-e.in, including its Privacy policy.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal custom_model" id="commitment_model">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close close_btn" data-dismiss="modal">&times;</button>
                      <div class="model-contact_block">
                        <div class="section-title">
                            <h1>MyStore-E<sup>TM</sup> Commitment</h1>
                        </div>
                         <ul>
                            <li>
                                <p><b>Sourcing:</b> MyStore-E<sup>TM</sup> invests and stock the goods for the Selling Partner.</p>
                            </li>
                            <li>
                                <p><b>Sales Platform:</b> MyStore-E<sup>TM</sup> provides online and/or offline tools for the Selling Partner.</p>
                            </li>
                            <li>
                                <p><b>Risk Mitigation:</b> Selling Partner pays for goods after making sales. MyStore-E<sup>TM</sup> mitigates business risk for the Selling Partner.</p>
                            </li>
                            <li>
                                <p><b>Logistics & Payment:</b> MyStore-E<sup>TM</sup> facilitates delivery and provides payment solutions.</p>
                            </li>
                        </ul>
                     </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal custom_model" id="selling_model">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close close_btn" data-dismiss="modal">&times;</button>
                      <div class="model-contact_block">
                        <div class="section-title">
                            <h1>Selling Partner Commitment</h1>
                        </div>
                        <ul>
                            <li>
                                <p><b>Sales:</b> Selling partners will focus only on sales of product and marketing their brand.</p>
                            </li>

                            <li>
                                <p><b>Conduct:</b> Selling Partner will not misrepresent, infringe, distribute, falsify, conduct  pyramid schemes, upload malware and virus, and/or conduct in any manner that harms www.mystore-e.in and Innovarium.</p>
                            </li>

                            <li>
                                <p><b>Products:</b>   Selling partner will not use www.mystore-e.in to sell products other than provided by www.mystore-e.in. Selling partner not misrepresent the warranties, guarantees & services associated with the products.</p>
                            </li>
                            <li>
                                <p><b>Ethics:</b> Selling partner will conduct business ethically and will not violate any  applicable laws of the land.</p>
                            </li>
                        </ul>
                     </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal custom_model" id="empowerment_model">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close close_btn" data-dismiss="modal">&times;</button>
                      <div class="model-contact_block">
                        <div class="section-title">
                            <h1>MyStore-E<sup>TM</sup> Empowerment</h1>
                        </div>
                        <ul>
                            <li>
                                <p><b>Equal opportunity:</b>  MyStore-E<sup>TM</sup> empowers any Indian citizen to own and run business  without discrimination. </p>
                            </li>    

                            <li>
                                <p><b>Unlimited opportunity:</b> MyStore-E<sup>TM</sup> empowers you to decide your profits and revenue. Empowers you to conduct business online and offline, anytime and anywhere in India.</p>
                            </li>
                                
                            <li>
                                <p><b>Brand Ownership: </b> MyStore-E<sup>TM</sup> empowers you to create and own your brand–Your brand displayed on your website, product display, invoice, etc. </p>
                            </li>    

                            <li>
                                <p><b>Ease of Business: </b> MyStore-E<sup>TM</sup> provides free website, provides offline display & products, free business analytics, delivery services, multiple payment facilities, etc. </p>
                            </li>    

                            <li>
                                <p><b>Investment-free: </b> MyStore-E<sup>TM</sup> invests in goods, digital photography and cataloging, server capacity. Plus you don’t spend on costs like rent, website building, general admin, salary.</p>
                            </li>
                        </ul>
                     </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal custom_model" id="faq_model">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                      <button type="button" class="close close_btn" data-dismiss="modal">&times;</button>
                      <div class="model-contact_block">
                            <div class="section-title">
                                <h1>FAQ’S</h1>
                            </div>
                            <div class="faq-content-block">
                                 <div id="accordion">
                                      <div class="card">
                                            <div class="card-header">
                                              <a class="card-link" data-toggle="collapse" href="#collapse1">
                                                1. Who can become a Selling Partner?
                                              </a>
                                            </div>
                                            <div id="collapse1" class="collapse show" data-parent="#accordion">
                                              <div class="card-body faq-content">
                                                  <p>Reply: Any Indian can become a Selling Partner. It is open to everyone who wants to have their own business</p>
                                              </div>
                                            </div>
                                      </div>
                                      <div class="card">
                                            <div class="card-header">
                                              <a class="card-link collapsed" data-toggle="collapse" href="#collapse2">
                                                2. How much do I need to invest?
                                              </a>
                                            </div>
                                            <div id="collapse2" class="collapse" data-parent="#accordion">
                                              <div class="card-body faq-content">
                                                  <p>Reply: You register on MyStore-E<sup>TM</sup> website and buy a package listed on the website. You don’t invest in stock, website development, product photography and cataloguing at all.</p>
                                              </div>
                                            </div>
                                      </div>
                                      <div class="card">
                                            <div class="card-header">
                                              <a class="card-link collapsed" data-toggle="collapse" href="#collapse3">
                                                3. What is the duration of being the Selling Partner?
                                              </a>
                                            </div>
                                            <div id="collapse3" class="collapse" data-parent="#accordion">
                                              <div class="card-body faq-content">
                                                  <p>Reply: The registration is one time only and it is life-long.</p>
                                              </div>
                                            </div>
                                      </div>
                                      <div class="card">
                                            <div class="card-header">
                                              <a class="card-link collapsed" data-toggle="collapse" href="#collapse4">
                                                4. What do I get in the kit?
                                              </a>
                                            </div>
                                            <div id="collapse4" class="collapse" data-parent="#accordion">
                                              <div class="card-body faq-content">
                                                <p>Reply: For the combo pack, you get:</p>
                                                <ul>
                                                    <li><p>Website with your brand name and logo (logo to be provided by the Selling Partner). Website will contain sales analytics. Your website can be accessed on mobile, PC and tablet.</p></li>
                                                    <li><p>A jewellery display kit with your name and logo (logo to be provided by the Selling Partner) </p></li>
                                                    <li><p>18 pieces total of high quality fashion jewellery across major categories (these are pre-set products)</p></li>
                                                    <li><p>Training.</p></li>
                                                </ul>
                                            </div>
                                            </div>
                                      </div>
                                      <div class="card">
                                            <div class="card-header">
                                              <a class="card-link collapsed" data-toggle="collapse" href="#collapse5">
                                                5. When do I pay for the products I sell? How will the sold product reach my client?
                                              </a>
                                            </div>
                                            <div id="collapse5" class="collapse" data-parent="#accordion">
                                              <div class="card-body faq-content">
                                                <p>Reply: You pay only when you sell the products. When you sell, you will get a tracking number. We will ship the product and you can track it till it is delivered.</p>
                                            </div>
                                            </div>
                                      </div>
                                      <div class="card">
                                            <div class="card-header">
                                              <a class="card-link collapsed" data-toggle="collapse" href="#collapse6">
                                               6. What is the profit I can expect to earn?
                                              </a>
                                            </div>
                                            <div id="collapse6" class="collapse" data-parent="#accordion">
                                              <div class="card-body faq-content">
                                                 <p>Reply: In our experience, you can expect to earn a <b>minimum of 30% gross profit</b> on the cost price. This <b>profit can go up to 100%</b> depending on the effort you put in marketing and reaching out to more clients.  We keep the cost price very competitive for our Selling Partners.</p>
                                            </div>
                                            </div>
                                      </div>
                                      <div class="card">
                                            <div class="card-header">
                                              <a class="card-link collapsed" data-toggle="collapse" href="#collapse7">
                                               7. How can I compete when other Selling Partners have same products on their website?
                                              </a>
                                            </div>
                                            <div id="collapse7" class="collapse" data-parent="#accordion">
                                              <div class="card-body faq-content">
                                                  <p>Reply: Take example of electronic store. There are many retail electronic stores in one area your city and they all have similar products like mobile, laptop, music system etc. All of them have sales and make profit. They all buy from the same wholesalers. In the same way, each Selling Partner is like any retail store, except that you do not invest in stock, website, cataloguing etc. </p>
                                            </div>
                                            </div>
                                      </div>
                                      <div class="card">
                                            <div class="card-header">
                                              <a class="card-link collapsed" data-toggle="collapse" href="#collapse8">
                                                8. So are there unlimited Selling Partners?
                                              </a>
                                            </div>
                                            <div id="collapse8" class="collapse" data-parent="#accordion">
                                              <div class="card-body faq-content">
                                                  <p>Reply: We restrict the number of Selling Partners in each area.</p>
                                            </div>
                                            </div>
                                      </div>
                                      <div class="card">
                                            <div class="card-header">
                                              <a class="card-link collapsed" data-toggle="collapse" href="#collapse9">
                                                9. Can I sell the pieces in the kit?
                                              </a>
                                            </div>
                                            <div id="collapse9" class="collapse" data-parent="#accordion">
                                              <div class="card-body faq-content">
                                                   <p>Reply: Yes. In fact, we encourage you to sell the 18 pieces and recover the cost of the package. In addition, you have the option to exchange the unsold pieces, provided they are unused and undamaged, after 2 months of registering as Selling Partner. </p>
                                              </div>
                                            </div>
                                      </div>
                                      <div class="card">
                                            <div class="card-header">
                                              <a class="card-link collapsed" data-toggle="collapse" href="#collapse10">
                                               10. What is the guarantee of the products?
                                              </a>
                                            </div>
                                            <div id="collapse10" class="collapse" data-parent="#accordion">
                                              <div class="card-body faq-content">
                                                   <p>Reply: Fashion/Imitation jewellery do not have any guarantee or warranty. However, we do advise how to take care of the jewellery so that your clients can enjoy them for a long time.</p>
                                              </div>
                                            </div>
                                      </div>
                                      <div class="card">
                                            <div class="card-header">
                                              <a class="card-link collapsed" data-toggle="collapse" href="#collapse11">
                                               11. What categories of products will be available to sell?
                                              </a>
                                            </div>
                                            <div id="collapse11" class="collapse" data-parent="#accordion">
                                              <div class="card-body faq-content">
                                                   <p>Reply: Currently it is high quality imitation jewellery. In future you will have women’s accessories, cosmetics, men’s products, home products, toys, children’s products etc.</p>
                                              </div>
                                            </div>
                                      </div>
                                      <div class="card">
                                            <div class="card-header">
                                              <a class="card-link collapsed" data-toggle="collapse" href="#collapse12">
                                               12.   What kind of jewellery products will be available to sell?
                                              </a>
                                            </div>
                                            <div id="collapse12" class="collapse" data-parent="#accordion">
                                              <div class="card-body faq-content">
                                                   <p>Reply: These are fashion jewellery/imitation jewellery. The categories are Bangles, Bracelets, Kadas, Earrings, Jhumkas, Studs, Solitaire rings, Fashion rings, Cocktail rings, Tennis bracelet, Necklace, Bridal wear, and many more</p>
                                              </div>
                                            </div>
                                      </div>
                                      <div class="card">
                                            <div class="card-header">
                                              <a class="card-link collapsed" data-toggle="collapse" href="#collapse13">
                                               13. Is there Annual Maintenance Cost (AMC) to be paid for the website?
                                              </a>
                                            </div>
                                            <div id="collapse13" class="collapse" data-parent="#accordion">
                                              <div class="card-body faq-content">
                                                   <p>Reply: There is no AMC for the first year. From the second year AMC charges are Rs.100 per month. This you can recover by selling just one product in a month. Please read the Terms & Conditions for more information. </p>
                                              </div>
                                            </div>
                                      </div>
                                      <div class="card">
                                            <div class="card-header">
                                              <a class="card-link collapsed" data-toggle="collapse" href="#collapse14">
                                               14. Can the Selling Partner buy goods on account for exhibitions?
                                              </a>
                                            </div>
                                            <div id="collapse14" class="collapse" data-parent="#accordion">
                                              <div class="card-body faq-content">
                                                   <p>Reply: We support The Selling Partner to buy goods, on returnable basis, for exhibitions. However conditions apply for this.  Please call for more information.</p>
                                              </div>
                                            </div>
                                      </div>
                                 </div> 
                            </div>
                     </div>
                </div>
            </div>
        </div>
    </div>

</body>

</html>