import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ErrorLogService } from 'src/app/services/error-log.service';
import { ValidationService } from 'src/app/services/validation.service';


import '../../../../assets/main/js/jquery.bxslider.js';
import '../../../../assets/main/js/popper.min.js';
import '../../../../assets/main/js/bootstrap.min.js';
// import '../../../../assets/main/js/brands.js';
// import '../../../../assets/main/js/solid.js';
// import '../../../../assets/main/js/fontawesome.js';
import '../../../../assets/main/js/custom.js';
import '../../../../assets/main/js/wow.js';
import * as $ from 'jquery';


@Component({
  selector: 'app-main-home',
  templateUrl: './main-home.component.html',
  styleUrls: [
    '../../../../assets/main/fonts/font.css',
    // '../../../../assets/main/style/fontawesome.css', 
    // '../../../../assets/main/style/brands.css', 
    // '../../../../assets/main/style/solid.css', 
    '../../../../assets/main/style/animate.css',
    '../../../../assets/main/style/bootstrap.min.css',
    // '../../../../assets/main/style/bootstrap-glyphicons.min.css',
    '../../../../assets/main/style/bootstrap-grid.min.css',
    '../../../../assets/main/style/jquery.bxslider.css',
    '../../../../assets/main/style/style.css',
    '../../../../assets/main/style/responsive.css'
  ],
})



export class MainHomeComponent implements OnInit {

  contactForm: FormGroup
  distributorForm: FormGroup
  state_data: []
  package_data: []
  package_full_data: any[]
  city_data: []

  constructor(
    private commonService: CommonService,
    private errorLogService: ErrorLogService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    
    this.contactForm = this.formBuilder.group({
      Name: ['', Validators.required],
      Phone: ['', Validators.required],
      Email: ['', Validators.required],
      Message: ['', Validators.required]
    });

    this.distributorForm = this.formBuilder.group({
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Email: ['', [Validators.required, ValidationService.emailValidator], this.emailValidator.bind(this)],
      // MobileNo: ['', Validators.required],
      Mobile: ['', [Validators.required, ValidationService.numberValidator, Validators.minLength(10), Validators.maxLength(10)], this.mobileValidator.bind(this)],
      CompanyName: ['', Validators.required],
      ZipCode: ['', Validators.required],
      ReferralCode: [''],
      State: ['', Validators.required],
      City: ['', Validators.required],
      PanCard: [''],
      Address: ['', Validators.required],
      TermsConditions: ['', Validators.required],
      Package: ['', Validators.required]
    });
    this.getState()
    this.getPackage()
  }

  onSubmit() {
    ValidationService.markFormGroupTouched(this.contactForm);
    if (this.contactForm.valid) {
      let Name = this.contactForm.controls.Name.value
      let Phone = this.contactForm.controls.Phone.value
      let Email = this.contactForm.controls.Email.value
      let Message = this.contactForm.controls.Message.value
      this.commonService.postData('home/contact', { Name: Name, Phone: Phone, Email: Email, Message: Message }).subscribe(
        response => {
          if (response.status) {
            this.errorLogService.handleSuccess(response.message);
            this.contactForm.reset()
          } else {
            this.errorLogService.handleError(response.message)
          }
        },
        error => this.errorLogService.handleError(error)
      );
    }
  }
  onDistributorSubmit() {
    ValidationService.markFormGroupTouched(this.distributorForm);
    if (this.distributorForm.valid) {

      this.commonService.postData('users/save', this.distributorForm.value).subscribe(
        response => {
          if (response.status) {
            this.errorLogService.handleSuccess(response.message);
            this.distributorForm.reset()
          } else {
            this.errorLogService.handleError(response.message)
          }
        },
        error => this.errorLogService.handleError(error)
      );
    }
  }

  getState() {
    this.commonService.getData('state').subscribe(
      response => {
        if (response.status) {
          this.state_data = response.data
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }
  
  selectPackage(id) {
    this.distributorForm.controls.Package.setValue(id)
  }

  getPackage() {
    this.commonService.getData('package').subscribe(
      response => {
        if (response.status) {
          this.package_data = response.data
          let temp_package = []
          response.data.forEach(function (element) {
            if(element.PackageID != 5) {
              temp_package.push(element)
            }
          });
          // console.log(temp_package)
          // console.log(this.package_data)
          this.package_full_data = temp_package
          
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }
  getCity(value = false) {
    let StateID = this.distributorForm.controls.State.value
    this.city_data = []
    if (StateID) {
      this.commonService.getData('city/state/' + StateID).subscribe(
        response => {
          if (StateID === value) {
            this.distributorForm.controls.City.setValue('')
          }
          if (response.status) {
            this.city_data = response.data
          } else {
            this.errorLogService.handleError(response.message)
          }
        },
        error => this.errorLogService.handleError(error)
      );
    }
  }

  emailValidator(control: FormControl) {
    const promise = new Promise((resolve, reject) => {
      if (control.value) {
        this.commonService.postData('users/check-email', { Email: control.value }).subscribe(
          response => {
            if (response.status) {
              resolve({ "emailExist": true });
            } else {
              resolve(null);
            }
          },
          error => this.errorLogService.handleError(error)
        );
      } else {
        resolve(null);
      }
    });
    return promise;
  }
  mobileValidator(control: FormControl) {
    const promise = new Promise((resolve, reject) => {
      if (control.value) {
        this.commonService.postData('users/check-mobile', { Mobile: control.value }).subscribe(
          response => {
            if (response.status) {
              resolve({ "mobileExist": true });
            } else {
              resolve(null);
            }
          },
          error => this.errorLogService.handleError(error)
        );
      } else {
        resolve(null);
      }
    });
    return promise;
  }

}
