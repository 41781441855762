import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorLogService } from './error-log.service';
import { ILogin } from '../shared/interfaces';
import { appApi } from "../shared/app.constants";

@Injectable({
  providedIn: 'root'
})

export class UserService {

  private token: string

  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private errorLogService: ErrorLogService
  ) { }

  public appHeader() {
    let token = this.getToken();
    return {
      headers: {
        'Authorization': 'Basic ' + token
      }
    };
  }

  private saveToken(token: string): void {
    localStorage.setItem('userToken', token)
    this.token = token
  }

  public getToken(): string {
    if (!this.token) {
      this.token = localStorage.getItem('userToken')
    }
    return this.token
  }

  public getUserDetails(): ILogin {
    const token = this.getToken()
    let payload
    if (token) {
      payload = token.split('.')[1]
      payload = window.atob(payload)
      return JSON.parse(payload)
    } else {
      return null
    }
  }

  get isLoggedIn() {
    return (this.getUserDetails()) ? true : false
  }

  login(data) {
    this.spinner.show();
    return this.http.post<ILogin>(appApi.login, data).subscribe(
      (result) => {
        this.spinner.hide();
        if (result.status) {
          this.saveToken(result.data)
          this.errorLogService.handleSuccess(result.message);
          this.router.navigate(['/'])
        } else {
          this.errorLogService.handleError(result.message)
        }
      },
      (error) => this.handleError(error)
    );

  }

  logout() {
    this.spinner.show();
    return this.http.get<ILogin>(appApi.logout, this.appHeader()).subscribe(
      (result) => {
        if (result.status) {
          this.spinner.hide();
          this.token = ''
          localStorage.removeItem('userToken');
          // window.localStorage.removeItem('userToken')
          this.errorLogService.handleSuccess(result.message)
          this.router.navigate(['/login'])
        } else {
          this.errorLogService.handleError(result.message)
        }
      },
      (error) => this.handleError(error)
    );
  }

  forgotPassword(data) {
    this.spinner.show();
    return this.http.post<ILogin>(appApi.forgotPassword, data).subscribe(
      (result) => {
        this.spinner.hide();
        if (result.status) {
          this.errorLogService.handleSuccess(result.message);
          this.router.navigate(['/login'])
        } else {
          this.errorLogService.handleError(result.message)
        }
      },
      (error) => this.handleError(error)
    );
  }

  changePassword(data) {
    this.spinner.show();
    return this.http.post<ILogin>(appApi.changePassword, data, this.appHeader()).subscribe(
      (result) => {
        this.spinner.hide();
        if (result.status) {
          this.errorLogService.handleSuccess(result.message);
          this.router.navigate(['/'])
        } else {
          this.errorLogService.handleError(result.message)
        }
      },
      (error) => this.handleError(error)
    );
  }

  public roleCheck(role_id): boolean {
    const user = this.getUserDetails()
    return (role_id.includes(JSON.parse(user['RoleID']))) ? true : false;
  }

  private handleError(error) {
    this.spinner.hide();
    this.errorLogService.handleError(error);
  }

}
