import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CartService {

  private cartCounter = new BehaviorSubject<number>(0);
  cast = this.cartCounter.asObservable();

  constructor() { }

  getCartCounter() {
    let cart = (localStorage.getItem('Cart')) ? Object.keys(JSON.parse(localStorage.getItem('Cart'))).length : 0;
    this.cartCounter.next(cart);
  }

}
