import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ICommon } from '../shared/interfaces';
import { createUrl } from "../shared/app.constants";
import { UserService } from './user.service';
import { ErrorLogService } from './error-log.service';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor(
        private http: HttpClient,
        private spinner: NgxSpinnerService,
        private errorLogService: ErrorLogService,
        private userService: UserService
    ) { }

    getData(method: string): Observable<ICommon> {
        this.spinner.show();
        return this.http.get<ICommon>(createUrl('api/' + method), this.userService.appHeader())
            .pipe(
                map((response) => {
                    if (response.code == 401) {
                        this.userService.logout()
                    }
                    this.spinner.hide();
                    return response;
                }),
                catchError(this.handleError.bind(this))
            );
    }

    postData(method, data): Observable<ICommon> {
        this.spinner.show();
        return this.http.post<ICommon>(createUrl('api/' + method), data, this.userService.appHeader())
            .pipe(
                map((response) => {
                    if (response.code == 401) {
                        this.userService.logout()
                    }
                    this.spinner.hide();
                    return response;
                }),
                catchError(this.handleError.bind(this))
            );
    }

    putData(method, data): Observable<ICommon> {
        this.spinner.show();
        return this.http.put<ICommon>(createUrl('api/' + method), data, this.userService.appHeader())
            .pipe(
                map((response) => {
                    if (response.code == 401) {
                        this.userService.logout()
                    }
                    this.spinner.hide();
                    return response;
                }),
                catchError(this.handleError.bind(this))
            );
    }

    deleteData(method): Observable<ICommon> {
        this.spinner.show();
        return this.http.delete<ICommon>(createUrl('api/' + method), this.userService.appHeader())
            .pipe(
                map((response) => {
                    if (response.code == 401) {
                        this.userService.logout()
                    }
                    this.spinner.hide();
                    return response;
                }),
                catchError(this.handleError.bind(this))
            );
    }

    private handleError(error) {
        this.spinner.hide();
        this.errorLogService.handleError(error);
    }

}
