//Original version created by Cory Rylan: https://coryrylan.com/blog/angular-2-form-builder-and-validation-management
import { AbstractControl, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ErrorLogService } from 'src/app/services/error-log.service';

@Injectable({
    providedIn: 'root'
})

export class ValidationService {

    static getValidatorErrorMessage(code: string, validatorValue?: any) {
        let config = {
            'required': `This field is required`,
            'invalidNumber': 'Only number allowed',
            'invalidMatchPassword': 'Password does not matched',
            'invalidCreditCard': 'Is invalid credit card number',
            'invalidEmailAddress': 'Invalid email address',
            'invalidPassword': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
            'minlength': `Minimum ${validatorValue.requiredLength} digit allowed`,
            'maxlength': `Maximum ${validatorValue.requiredLength} digit allowed`,
            'min': `Minimum ${validatorValue.min} value allowed`,
            'max': `Maximum ${validatorValue.max} value allowed`,
            'emailExist': `Email already exist`,
            'mobileExist': `Mobile already exist`,
            'productCode': `Product code already exist`,
            'hsnCode': `HSN code already exist`
        };
        return config[code];
    }

    static creditCardValidator(control: AbstractControl) {
        // Visa, MasterCard, American Express, Diners Club, Discover, JCB
        if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
            return null;
        } else {
            return { 'invalidCreditCard': true };
        }
    }

    static emailValidator(control: AbstractControl) {
        // RFC 2822 compliant regex
        if (control.value) {
            if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
                return null;
            } else {
                return { 'invalidEmailAddress': true };
            }
        } else {
            return null;
        }
    }

    static passwordValidator(control: AbstractControl) {
        // {6,100}           - Assert password is between 6 and 100 characters
        // (?=.*[0-9])       - Assert a string has at least one number
        if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
            return null;
        } else {
            return { 'invalidPassword': true };
        }
    }

    static numberValidator(control: AbstractControl) {
        // Only number allows
        if (control.value) {
            let string = control.value.toString()
            if (string.match(/^-?[\d.]+(?:e-?\d+)?$/)) {
                return null;
            } else {
                return { 'invalidNumber': true };
            }
        } else {
            return null;
        }

    }

    static matchPasswordValidator(control: AbstractControl) {
        // Match password
        if (control.get('Password').value && control.get('ConfirmPassword').value) {
            if (control.get('Password').value === control.get('ConfirmPassword').value) {
                return null;
            } else {
                return { 'invalidMatchPassword': true };
            }
        } else if (control.get('Password').value) {
            return { 'required': true };
        } else {
            return null;
        }

    }

    static markFormGroupTouched(formGroup: FormGroup) {
        Object.values(formGroup.controls).forEach(control => {
            control.markAsTouched();
            if (Array.isArray(control['controls']) && control['controls']) { //FormGroup
                control['controls'].forEach(c => this.markFormGroupTouched(c));
            } else if (control['controls']) {
                Object.values(<FormGroup>control['controls']).forEach(c => {
                    c.markAsTouched();
                });
            }
        });
    }

    static emailExistValidator(control: AbstractControl) {
        var commonService: CommonService;
        var errorLogService: ErrorLogService;
        if (control.value) {
            commonService.postData('users/check-email', { Email: control.value }).subscribe(
                response => {
                    if (response.status) {
                        return { "emailExist": true };
                    } else {
                        return null;
                    }
                },
                error => errorLogService.handleError(error)
            );
        } else {
            return null;
        }
    }
}