import { environment } from '../../environments/environment';

export function createUrl(actionName: string): string {
  return `${environment.apiHost}${actionName}`;
}

export const appApi = {
  login: createUrl('api/auth/login'),
  logout: createUrl('api/auth/logout'),
  forgotPassword: createUrl('api/auth/forgot-password'),
  changePassword: createUrl('api/auth/change-password')
};

export const appURL = {
  baseUrl: environment.apiHost,
  uploadsPath: `${environment.apiHost}uploads/`,
  productPath: `${environment.apiHost}uploads/product/`
};

export const modalConfig = {
  class: 'modal-dialog-centered',
  ignoreBackdropClick: true
}

export const smallModalConfig = {
  class: 'modal-sm modal-dialog-centered',
  ignoreBackdropClick: true
}

export const mediumModalConfig = {
  class: 'modal-md modal-dialog-centered',
  ignoreBackdropClick: true
}

export const largeModalConfig = {
  class: 'modal-lg modal-dialog-centered',
  ignoreBackdropClick: true
}

export const xLargeModalConfig = {
  class: 'modal-xl modal-dialog-centered',
  ignoreBackdropClick: true
}

// export const appHeader = {
//   headers: {
//     'Authorization': 'Basic ' + localStorage.getItem('userToken')
//   }
// };

export const roleNames = {
  superAdmin: 'Super Admin',
  distributor: 'Distributor'
};

export const appVariables = {
  accessToken: 'X-Auth-Token',
  contentType: 'application/json',
  resourceActions: {
    getActionName: 'Read',
    addActionName: 'Create',
    updateActionName: 'Update',
    deleteActionName: 'Delete',
  }
};

export const errorMessage = {
  pageNotFound: 'Page not found',
  forbidden: 'Forbidden',
  internalServerError: 'Internal Server error',
  unknownError: 'Unknown Error (Response not get)',
  httpError: 'There was an HTTP error.',
  typeError: 'There was a Type error.',
  generalError: 'There was a general error.',
  somethingWrong: 'Nobody threw an Error but something wrong!'
}

export class AppConstants { }
