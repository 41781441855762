<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="icon-box">
                <i class="fa fa-close text-danger"></i>
            </div>
            <h6 class="text-center">Are you sure want to delete?</h6>
            <p class="txt-grey">This action is final and you will be unable to recover any data.</p>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-sm waves-effect waves-light btn-primary" (click)="accept()">Yes</button>
    <button type="button" class="btn btn-sm waves-effect waves-light btn-secondary" (click)="decline()">No</button>
</div>