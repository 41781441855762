import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';

@Injectable()
export class ConfirmationDialogService {

    bsModalRef: BsModalRef;

    constructor(private modalService: BsModalService) { }

    public confirm(title: string, callback) {
        const initialState = {
            title: title
        };
        this.bsModalRef = this.modalService.show(ConfirmationDialogComponent, { initialState });
        this.bsModalRef.content.onClose.subscribe(result => {
            callback(result);
        })
    }
}
