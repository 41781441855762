import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { errorMessage } from '../shared/app.constants'
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class ErrorLogService extends ErrorHandler {

  constructor(public toastr: ToastrService) {
    super();
  }

  handleError(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (typeof error.error.message !== 'undefined') {
        this.toastr.error(error.error.message);
      } else if (typeof error.message !== 'undefined') {
        if (error.status === 404) {
          this.toastr.error(errorMessage.pageNotFound, '404');
        } else if (error.status === 500) {
          this.toastr.error(errorMessage.internalServerError, '500');
        } else if (error.status === 403) {
          this.toastr.error(errorMessage.forbidden, '403');
        } else if (error.status === 0) {
          this.toastr.error(errorMessage.unknownError, '0');
        } else {
          this.toastr.error(error.message);
        }
      } else if (typeof error.status !== 'undefined') {
        this.toastr.error(error.status.toString());
      } else {
        this.toastr.error(errorMessage.httpError);
      }
      // console.error(date, AppConstants.httpError, error.message, 'Status code:',
      // (<HttpErrorResponse>error).status);
    }
    else if (error instanceof TypeError) {
      if (typeof error.message !== 'undefined') {
        this.toastr.error(error.message);
      } else {
        this.toastr.error(errorMessage.typeError);
      }
      // console.error(date, AppConstants.typeError, error.message, error.stack);
    }
    else if (error instanceof Error) {
      if (typeof error.message !== 'undefined') {
        this.toastr.error(error.message);
      } else {
        this.toastr.error(errorMessage.generalError);
      }
      // console.error(date, AppConstants.generalError, error.message, error.stack);
    }
    else if (error instanceof ErrorEvent) {
      if (typeof error.message !== 'undefined') {
        this.toastr.error(error.message);
      } else {
        this.toastr.error(errorMessage.generalError);
      }
      //A client-side or network error occurred. Handle it accordingly.
      // console.error(date, AppConstants.generalError, error.message);
    }
    else {
      if (typeof error !== 'undefined') {
        this.toastr.error(error);
      } else {
        this.toastr.error(errorMessage.somethingWrong);
      }
      // console.error(date, AppConstants.somethingHappened, error.message, error.stack);
    }
  }
  handleSuccess(message: any) {
    this.toastr.success(message);
  }
  handleWarning(message: any) {
    this.toastr.warning(message);
  }
}