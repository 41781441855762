import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable, of as observableOf } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  // showHead: Observable<boolean>;
  showHead: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title) {
    // this.showHead = this.userService.isLoggedIn;
    // on route change to '/login', set the variable showHead to false

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/login' || event.url === '/' || event.url === '/forgot-password' || event.url === '/change-password' || event.url === '/page-not-found' || event.urlAfterRedirects === '/page-not-found') {
          this.showHead = false;
          // this.showHead = this.userService.isLoggedIn;
        } else {
          this.showHead = true;
          // this.showHead = this.userService.isLoggedIn;
        }
      }
    });
  }
  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        };
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe((event) => this.titleService.setTitle(event['title']));
  }
}