import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

    title: string;
    public onClose: Subject<boolean>;

    constructor(public bsModalRef: BsModalRef) {
    }

    ngOnInit() {
        this.onClose = new Subject();
    }

    public decline() {
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

    public accept() {
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

}
